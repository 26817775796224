import React from "react"
import BackgroundImage from "gatsby-background-image"
import { Link } from "gatsby"
import { Button } from "../buttons"

const TrippleThingy = ({ Games }) => {
  return (
    <>
      <div
        style={{ background: `var(--bgAlt)` }}
        className="px-2 md:px-8 py-3 lg:py-16 font-sans justify-between"
      >
        <div className="flex flex-wrap justify-evenly">
          {Games.map(Games => (
            <div className="w-full my-2 lg:my-0 lg:w-2/6">
              <div className="card h-40">
                <BackgroundImage
                  style={{
                    backgroundPosition: `center center`,
                    backgroundSize: `cover`,
                  }}
                  className="h-full"
                  Tag="section"
                  fluid={Games.bg}
                >
                  <div className="flex w-full h-full justify-center items-center transition duration-500 ease-in-out bg-gray-800 bg-opacity-25 hover:bg-gray-800 hover:bg-opacity-75 ">
                    <Link to={Games.url} className="hover:no-underline">
                      <Button>{Games.name}</Button>
                    </Link>
                  </div>
                </BackgroundImage>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default TrippleThingy
