import React from "react"
import BackgroundImage from "gatsby-background-image"
import { Link } from "gatsby"
import { Button } from "../buttons"

const Hero = ({ heroTitle, heroBG, buttonText, url }) => {
  return (
    <div className="flex flex-wrap mb-3 px-2 md:px-8 py-2 lg:py-8 lg:pb-24">
      <div className="flex items-center w-full mb-3 sm:w-full md:w-full lg:w-4/12 lg:justify-center lg:mb-0">
        <div className="font-sans flex w-full text-3xl mb-3 sm:w-full md:w-full lg:w-9/12 lg:text-5xl xl:text-6xl font-semibold leading-none">
          <div
            className="pb-2"
            style={{
              background: `linear-gradient(0deg,#1cb855, #169143)`,
              WebkitTextFillColor: `transparent`,
              WebkitBackgroundClip: `text`,
            }}
          >
            {heroTitle}
          </div>
        </div>
      </div>
      <div className="h-64 w-full lg:w-8/12">
        <BackgroundImage
          className="h-64 w-full card"
          Tag="section"
          fluid={heroBG}
        >
          <div className="hover:bg-gray-800 hover:bg-opacity-50 bg-gray-800 bg-opacity-75 flex transition duration-500 ease-in-out w-full h-full justify-center items-center">
            <Link to={url} className="hover:no-underline">
              <div>
                <Button>{buttonText}</Button>
              </div>
            </Link>
          </div>
        </BackgroundImage>
      </div>
    </div>
  )
}

export default Hero
