import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/hero"
import GamesList from "../components/gamesList"
import DiscordBox from "../components/discordBox"

// import Announcement from "../components/announcement"

const IndexPage = ({ data }) => {
  const Games = [
    {
      name: "World of Warcraft",
      bg: data.wowImage.childImageSharp.fluid,
      url: "/wow",
    },
    {
      name: "Wildstar",
      bg: data.wildstarImage.childImageSharp.fluid,
      url: "/wildstar",
    },
  ]
  return (
    <Layout>
      <SEO title="Welcome!" />
      <Hero
        heroTitle={"The Burning Crusade has come!"}
        heroBG={data.randomImage.childImageSharp.fluid}
        buttonText={"Click for more information"}
        url={"/bc-classic-release"}
      ></Hero>
      <DiscordBox color="bg-gray-700" />
      <GamesList Games={Games} />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    randomImage: file(
      relativePath: { eq: "media_image_bcc_netherwing_full.jpeg" }
    ) {
      childImageSharp {
        fluid(pngQuality: 90, quality: 90, maxWidth: 2542) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    appImage: file(relativePath: { eq: "arctium-app-01.png" }) {
      childImageSharp {
        fluid(pngQuality: 90, quality: 90, maxWidth: 2542) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    wowImage: file(relativePath: { eq: "wow-pandaren.jpg" }) {
      childImageSharp {
        fluid(quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wildstarImage: file(relativePath: { eq: "wildstar2.png" }) {
      childImageSharp {
        fluid(quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
