import React from "react"
import DiscordLogo from "../../images/discordLogo.png"
import { HugeButton } from "../buttons"

const DiscordBox = ({ color }) => {
  return (
    <>
      <div className="px-5 md:px-8 bg-gray-700">
        {" "}
        <div className="flex flex-wrap justify-evenly py-5 md:py-12 items-center text-white">
          <div className="">
            <h1>Meet our Discord!</h1>
            <ul className="mb-0 list-disc font-thin text-xl">
              <li>We have amazing people</li>
              <li>Big focus on our diverse Community</li>
              <li>Community driven support</li>
              <li>Occasional giveaways</li>
              <li>Memes</li>
            </ul>
          </div>
          <div className="text-center">
            <div>
              <img
                alt="discordLogo"
                className="flex-col"
                style={{ width: `400px` }}
                src={DiscordLogo}
              />
            </div>

            <div>
              <a href="https://arctium.io/discord">
                <HugeButton>Join now</HugeButton>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DiscordBox
