import React from "react"
import { motion } from "framer-motion"

const Button = ({ children, onClick }) => {
  return (
    <>
      <motion.button
        onClick={onClick}
        whileHover={{
          top: `2px`,
        }}
        whileTap={{
          top: `3px`,
          boxShadow: `0 0px 0px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)`,
        }}
        style={{ outline: `none`, position: `relative` }}
        className="px-4 mx-1 rounded-l-full rounded-full py-2 bg-green-500 text-white font-semibold shadow-md"
      >
        {children}
      </motion.button>
    </>
  )
}
const DeclineButton = ({ children, onClick }) => {
  return (
    <>
      <motion.button
        onClick={onClick}
        whileHover={{
          top: `2px`,
        }}
        whileTap={{
          top: `3px`,
          boxShadow: `0 0px 0px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)`,
        }}
        style={{ outline: `none`, position: `relative` }}
        className="px-4 mx-1 rounded-l-full rounded-full py-2 bg-red-700 text-white font-semibold shadow-md"
      >
        {children}
      </motion.button>
    </>
  )
}

const SmallButton = ({ children }) => {
  return (
    <>
      <motion.button
        whileHover={{
          top: `3px`,
        }}
        whileTap={{
          top: `3px`,
          boxShadow: `0 0px 0px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)`,
        }}
        style={{ outline: `none`, position: `relative` }}
        className="px-3 mx-1 text-sm rounded-l-full rounded-full py-1 bg-green-500 text-white font-semibold shadow-md"
      >
        {children}
      </motion.button>
    </>
  )
}
const HugeButton = ({ children }) => {
  return (
    <>
      <motion.button
        whileHover={{
          top: `3px`,
        }}
        whileTap={{
          top: `3px`,
          boxShadow: `0 0px 0px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)`,
        }}
        style={{ outline: `none`, position: `relative` }}
        className="px-5 mx-1 text-xl rounded-l-full rounded-full py-3 bg-white text-gray-800 font-semibold shadow-md"
      >
        {children}
      </motion.button>
    </>
  )
}
const ComboButton = ({ children }) => {
  return (
    <>
      <motion.div
        whileHover={{
          top: `2px`,
        }}
        whileTap={{
          top: `3px`,
          boxShadow: `0 0px 0px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)`,
        }}
        style={{ outline: `none`, position: `relative` }}
        className="flex mb-3"
      >
        <button className="pl-3 pr-2 ml-1 rounded-l-full py-2 bg-green-500 text-white font-semibold shadow-md">
          {children}
        </button>
        <div className="pl-2 pr-3 mr-1 rounded-r-full py-2 bg-green-800 text-white font-semibold shadow-md">
          ComboText
        </div>
      </motion.div>
    </>
  )
}
export { Button, SmallButton, HugeButton, DeclineButton }
